(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("vue"), require("lodash-es"));
	else if(typeof define === 'function' && define.amd)
		define(["vue", "lodash-es"], factory);
	else if(typeof exports === 'object')
		exports["CKEditor"] = factory(require("vue"), require("lodash-es"));
	else
		root["CKEditor"] = factory(root["vue"], root["lodash-es"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__380__, __WEBPACK_EXTERNAL_MODULE__76__) => {
return 